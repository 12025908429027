<template>
  <RsModal
    class="model-tip-summary__wrapper"
    :title="tip.value"
    @dismiss="closeModal"
  >
    <div>
      <div class="max-h-[480px]">
        <TutorialContent  :content="tip.nestedShowing" />
      </div>
    </div>

    <template #header>
      <div class="flex justify-between items-end w-full md:px-4">
        <RsHeadline variant="h2" class="flex flex-row items-center">
          {{ tip.value }}
          <StatusIcon v-if="status" class="ml-2 flex-shrink-0" :status="status" />
        </RsHeadline>
        <IcCloseBlack class="cursor-pointer" width="24" @click="closeModal"/>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end w-full md:px-4 mb:pb-4">
        <RsButton class="ml-auto !px-10" variant="secondary" @click="closeModal">
          {{ $t('common.close') }}
        </RsButton>
      </div>
    </template>
  </RsModal>
</template>

<script setup lang="ts">
import { IcCloseBlack } from '~/components/common/icons'
import { type Manufacturer, type OnboardingPageContent, TrackingTipStatus } from '~/services/entities'
import TutorialContent from '../tutorialPage/TutorialContent.vue'
import StatusIcon from '~/components/tutorialBlocks/TutorialContentTipsItem/StatusIcon.vue'
import { FinalCheckPageContextProviderKey, GlobalManufacturersProviderKey } from '~/services/constants'

const props = defineProps<{
  tip: OnboardingPageContent,
  status: TrackingTipStatus,
  manufacturerList: Manufacturer[]
}>()

const { manufacturerList } = toRefs(props)

provide(GlobalManufacturersProviderKey, manufacturerList)
provide(FinalCheckPageContextProviderKey, true)

const emit = defineEmits(['commit'])
const closeModal = () => emit('commit')
</script>

<style scoped>
.model-tip-summary__wrapper :deep(.modal__window) {
  @apply max-h-[480px] rounded-lg h-auto;
}
.model-tip-summary__wrapper :deep(.modal__body) {
  @apply max-h-full;
}
.model-tip-summary__wrapper :deep(.modal__header) {
  @apply border-b-0;
}
.model-tip-summary__wrapper :deep(.modal__footer) {
  @apply border-t-0;
}
.model-tip-summary__wrapper {
  @apply !p-4 items-center;
}
@screen sm {
  .model-tip-summary__wrapper :deep(.modal__window) {
    @apply !max-w-2xl max-h-[640px] !h-auto;
  }
}
</style>
